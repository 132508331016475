import React, { useState } from "react";
import { graphql } from "gatsby";

import Article from "~/models/article";
import Page from "~/models/page";
import Frame from "~/views/layout/frame";
import SEO from "~/views/components/generic/seo";
import Header from "~/views/compositions/navigation/header";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Collection from "~/models/collection";
import Container from "~/views/layout/container";
import Section from "~/views/layout/section";
import ArticleCard from "~/views/components/article/card";
import Button from "~/views/elements/button/link";
import ArticleItem from "~/views/components/article/item";
import PageHeader from "~/views/compositions/category/header";
import Label from "~/models/label";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.CategoryPageQuery;
};

const background: any = {
  "/ux-design/": "#8ECFE0",
  "/ui-design/": "#EBED9C",
  "/web-design/": "#B6C5C1",
  "/branding/": "#F5F5EB",
  "/graphic-design/": "#FDF5F3",
  "/business/": "#E2E6E8",
};

const CategoryPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const collections = data.collections.nodes.map(
    (collection) =>
      new Collection(collection as Queries.ContentfulCollectionPage)
  );
  const label = new Label(data.label as Queries.ContentfulLabel);

  const defaultPageInfo = data.latestArticles.pageInfo;
  const defaultLatestArticles = data.latestArticles.nodes.map(
    (article) => new Article(article as Queries.ContentfulPage)
  );

  const [latestArticles, setLatestArticles] = useState(defaultLatestArticles);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);

  const onLoadMore = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `/page-data${page.url.pathname}pages/${
          pageInfo.currentPage + 1
        }/page-data.json`
      ).then((response) => response.json());

      const nextPage = response.result.data.articles.nodes.map(
        (article: Queries.ContentfulPage) => new Article(article)
      );

      setLatestArticles(latestArticles.concat(nextPage));
      setPageInfo(response.result.data.articles.pageInfo);
    } catch (error) {}
  };

  return (
    <Frame>
      <Header />

      <div
        className=""
        style={{
          backgroundColor: background[page.url.pathname],
        }}>
        <PageHeader
          title={`${label.name}`}
          description={`${label.name}に関する学習コンテンツや最新情報などのオリジナル記事を配信しています。`}
          page={page}
        />
      </div>

      <Breadcrumb
        breadcrumb={page.breadcrumb}
        className="xl:max-w-[1328px] mb-12"
      />

      <React.Fragment>
        {collections.map((collection) => (
          <Container key={collection.id} className="pb-container">
            <Section title={collection.title} label={collection.subtitle}>
              <ul className="grid sm:grid-cols-3 md:grid-cols-4 grid-gap-x grid-gap-y">
                {collection.items.map((article) => (
                  <li key={article.id}>
                    <ArticleCard article={article} />
                  </li>
                ))}
              </ul>
            </Section>
          </Container>
        ))}
      </React.Fragment>

      <Container className="pb-container grid grid-cols-12 grid-gap-x">
        <Section
          title="最新の記事"
          label="Latest Articles"
          className="col-start-1 col-end-13 lg:col-end-10">
          <ul className="grid grid-gap-y">
            {latestArticles.map((article) => (
              <li key={article.id}>
                <ArticleItem article={article} />
              </li>
            ))}
          </ul>
        </Section>
      </Container>

      <React.Fragment>
        {pageInfo.hasNextPage && (
          <Button
            label="さらに読み込む"
            to={`${page.url.pathname}pages/${pageInfo.currentPage + 1}/`}
            onClick={onLoadMore}>
            さらに読み込む
          </Button>
        )}
      </React.Fragment>
    </Frame>
  );
};

export default CategoryPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const defaultPageInfo = data.latestArticles.pageInfo;

  return (
    <SEO page={page}>
      {defaultPageInfo.hasNextPage && (
        <link
          rel="next"
          href={`${process.env.GATSBY_SITE_URL}${page.url.pathname}pages/${
            defaultPageInfo.currentPage + 1
          }/`}
        />
      )}
    </SEO>
  );
};

export const query = graphql`
  query CategoryPage(
    $slug: String
    $pathname: String
    $skip: Int
    $limit: Int
  ) {
    label: contentfulLabel(slug: { eq: $slug }) {
      id
      name
    }
    page: contentfulPage(url: { pathname: { eq: $pathname } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      image {
        cover {
          publicURL
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData(width: 640, placeholder: NONE, formats: [WEBP])
          }
        }
      }
      type
      releasedAt
      updatedAt
    }
    collections: allContentfulCollectionPage(
      filter: { labels: { elemMatch: { slug: { in: [$slug] } } } }
      sort: { priority: ASC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        slug
        title
        subtitle
        items {
          ...PageThumbnailFields
        }
        labels {
          ...LabelFields
        }
      }
    }
    latestArticles: allContentfulPage(
      filter: {
        type: { eq: "Article" }
        labels: { elemMatch: { slug: { in: [$slug] } } }
      }
      sort: { releasedAt: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        ...PageThumbnailFields
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;
