import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Label from "~/models/label";
import Tag from "~/views/elements/generic/tag";
import Forward from "~/views/identity/forward";
import News from "~/models/news";
import cx from "classnames";

function NewsMenu(props: any) {
  const { allContentfulLabel, allContentfulPage }: Queries.NewsMenuQuery =
    useStaticQuery(
      graphql`
        query NewsMenu {
          allContentfulLabel(
            filter: {
              slug: {
                in: [
                  "spotify"
                  "notion"
                  "apple"
                  "product-release"
                  "line"
                  "wantedly"
                  "tiktok"
                  "slack"
                  "google"
                  "figma"
                  "android"
                  "renewal"
                ]
              }
            }
            limit: 16
          ) {
            nodes {
              ...LabelFields
            }
          }
          allContentfulPage(
            filter: { type: { eq: "News" } }
            sort: { releasedAt: DESC }
            limit: 5
          ) {
            nodes {
              ...PageThumbnailFields
            }
          }
        }
      `
    );

  const labels = allContentfulLabel.nodes.map(
    (label) => new Label(label as Queries.ContentfulLabel)
  );
  const news = allContentfulPage.nodes.map(
    (news) => new News(news as Queries.ContentfulPage)
  );

  return (
    <div
      className={cx(
        "max-w-[740px] mx-auto pt-8 pb-12 flex gap-10",
        props.className
      )}
    >
      <div>
        <div className="mb-4 flex items-center">
          <h4 className="w-[100px] text-2xl font-extrabold text-neutral-90">
            Latest
          </h4>
          <Link
            aria-label="最新のニュース一覧"
            to="/news/"
            className="group flex gap items-center"
          >
            <span className="text-neutral-60 font-medium text-3xs leading-[24px] mt-[3px] group-hover:text-cyan-strong">
              最新のニュース一覧
            </span>
            <Forward />
          </Link>
        </div>
        <ul className="marker:text-neutral-90 list-outside list-disc pl-4">
          {news.map((news: any) => (
            <li
              key={news.id}
              className="mb-4 last:mb-0 sm:text-xs leading-[20px] text-xs hover:underlined-link"
            >
              <Link aria-label={news.title} to={news.url.pathname}>
                {news.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="w-[380px]">
        <div className="mb-4 flex items-center">
          <h4 className="w-[100px] text-2xl font-extrabold text-neutral-90">
            Tags
          </h4>
          <Link
            aria-label="タグを検索"
            to="/tags/"
            className="group flex gap items-center"
          >
            <span className="text-neutral-60 font-medium text-3xs leading-[24px] mt-[3px] group-hover:text-cyan-strong">
              タグを検索
            </span>
            <Forward />
          </Link>
        </div>
        <ul>
          {labels.map((label) => (
            <li key={label.id} className="inline-block mb-3 mr-2">
              <Tag label={label} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default NewsMenu;
