import React, { useState } from "react";
import { graphql } from "gatsby";

import Page from "~/models/page";
import Article from "~/models/article";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import ArticleItem from "~/views/components/article/item";
import PopularArticles from "~/views/compositions/article/popular";
import Button from "~/views/elements/button/link";
import PageHeader from "~/views/compositions/generic/header";

type Props = {
  data: Queries.ArticleListPageQuery;
};

const ArticleListPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  const defaultPageInfo = data.articles.pageInfo;
  const defaultArticles = data.articles.nodes.map(
    (article) => new Article(article as Queries.ContentfulPage)
  );

  const [articles, setArticles] = useState(defaultArticles);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);

  if (defaultPageInfo.currentPage > 1) {
    page.url.pathname = `/articles/pages/${pageInfo.currentPage}/`;
  }

  const onLoadMore = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `/page-data/articles/pages/${pageInfo.currentPage + 1}/page-data.json`
      ).then((response) => response.json());

      const nextPage = response.result.data.articles.nodes.map(
        (article: Queries.ContentfulPage) => new Article(article)
      );

      setArticles(articles.concat(nextPage));
      setPageInfo(response.result.data.articles.pageInfo);
    } catch (error) {}
  };

  return (
    <Frame>
      <Header />

      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <PageHeader
        title="最新記事"
        description="「より良い未来をつくる」というテーマのもと、デザイナーが日々の業務や生活、自身のキャリアに組み込むことができるような多彩な視点を発信しています。"
      />

      <Container className="pb-container grid grid-cols-12 grid-gap-x md:max-w-[840px] w1180:max-w-full mx-auto">
        <div className="col-start-1 col-end-13 w1180:col-end-10">
          <ul className="grid grid-gap-y">
            {articles.map((article) => (
              <li key={article.id}>
                <ArticleItem article={article} />
              </li>
            ))}
          </ul>
        </div>

        <div className="hidden w1180:block w1180:col-start-10 w1180:col-end-13 mt-12 sm:mt-24 w1180:mt-32">
          <PopularArticles />
        </div>
      </Container>

      <React.Fragment>
        {pageInfo.hasNextPage && (
          <Button
            label="さらに読み込む"
            to={`/articles/pages/${pageInfo.currentPage + 1}/`}
            onClick={onLoadMore}>
            さらに読み込む
          </Button>
        )}
      </React.Fragment>
    </Frame>
  );
};

export default ArticleListPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const defaultPageInfo = data.articles.pageInfo;

  if (defaultPageInfo.currentPage > 1) {
    page.url.pathname = `/articles/pages/${data.articles.pageInfo.currentPage}/`;
  }

  return (
    <SEO page={page}>
      {defaultPageInfo.hasPreviousPage && (
        <link
          rel="prev"
          href={
            defaultPageInfo.currentPage == 2
              ? `${process.env.GATSBY_SITE_URL}/articles/`
              : `${process.env.GATSBY_SITE_URL}/articles/pages/${
                  defaultPageInfo.currentPage - 1
                }/`
          }
        />
      )}

      {defaultPageInfo.hasNextPage && (
        <link
          rel="next"
          href={`${process.env.GATSBY_SITE_URL}/articles/pages/${
            defaultPageInfo.currentPage + 1
          }/`}
        />
      )}
    </SEO>
  );
};

export const query = graphql`
  query ArticleListPage($skip: Int, $limit: Int) {
    page: contentfulPage(url: { pathname: { eq: "/articles/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    articles: allContentfulPage(
      filter: { type: { eq: "Article" } }
      sort: { releasedAt: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        ...PageThumbnailFields
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;
