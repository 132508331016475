import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Url from "~/models/url";
import News from "~/models/news";
import Page from "~/models/page";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import SEO from "~/views/components/generic/seo";
import Header from "~/views/compositions/navigation/header";
import RichText from "~/views/components/content/richtext";
import Tag from "~/views/elements/generic/tag";
import Category from "~/views/elements/generic/category";
import NewsItem from "~/views/components/news/item";
import Section from "~/views/layout/section";
import ShareButtons from "~/views/components/generic/share-buttons";
import RecommendedArticles from "~/views/compositions/article/recommended";
import formatdate from "~/helpers/formatdate";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import useScrollLogs from "~/helpers/use-scroll-logs";
import SideNavigationBanner from "~/views/components/advertisement/side-navigation";
import Tagline from "~/views/components/generic/tagline";
import Kuro from "~/views/components/generic/kuro";

type Props = {
  data: Queries.NewsPageQuery;
};

const NewsPage = ({ data }: Props) => {
  useScrollLogs("content_view");

  const page = new Page(data.page as Queries.ContentfulPage);

  const latestNews = data.news.nodes.map(
    (news) => new News(news as Queries.ContentfulPage)
  );

  return (
    <Frame>
      <Header />

      <Tagline className="hidden sm:block" />

      <Container className="pb-3 pt-5 w1180:pt-7 w1180:pb-3 grid-cols-12 grid-gap-x md:max-w-[780px] w1180:max-w-full mx-auto hidden sm:grid">
        <ul className="col-start-1 col-end-13 w1180:col-start-2 w1180:col-end-9">
          {page.breadcrumb.map((url: Url, index: number) => {
            return (
              <li
                key={url.id}
                className="inline-block mr-2 font-medium text-4xs">
                <Link
                  aria-label={url.name}
                  to={url.pathname}
                  className="mr-2 inline-block align-middle hover:underline text-neutral-60 hover:underline-offset-2">
                  {url.name}
                </Link>

                {page.breadcrumb.length !== index + 1 && (
                  <span className="inline-block align-middle text-neutral-90">
                    /
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </Container>

      <Container className="pb-8 pt-6 w1180:pt-7 w1180:pb-16 grid grid-cols-12 grid-gap-x md:max-w-[780px] w1180:max-w-full mx-auto">
        <div className="col-start-1 col-end-13 w1180:col-start-2 w1180:col-end-9">
          {page.category && <Category className="mb-4" label={page.category} />}

          <h1 className="mb-4 text-4xl sm:text-7xl w1180:text-9xl font-bold leading-[1.4]">
            {page.title}
          </h1>

          <div className="flex justify-between items-start">
            <div className="sm:flex gap-8 items-center">
              <div className="flex items-center">
                <span className="mr-4 text-2xs text-neutral-60">
                  {formatdate(page.releasedAt)}
                </span>

                <span className="text-2xs text-neutral-60">編集部</span>
              </div>

              <div>
                {page.labels.map((label) => (
                  <Tag
                    key={label.id}
                    label={label}
                    className="mr-2 mt-2 sm:mt-0"
                  />
                ))}
              </div>
            </div>

            <ShareButtons page={page} />
          </div>
        </div>
      </Container>

      <Container className="grid grid-cols-12 grid-gap-x md:max-w-[780px] w1180:max-w-full mx-auto">
        <div className="col-start-1 col-end-13 w1180:col-start-2 w1180:col-end-9">
          <div className="mb-16 sm:mb-[128px]">
            <div className="relative">
              <GatsbyImage
                image={getImage(page.image?.cover as any)!}
                alt={page.title!}
                className="mb-12 sm:mb-16 rounded-2xl isolate bg-neutral-99 border border-neutral-90-0.6"
                loading="eager"
              />
            </div>
          </div>

          <RichText id="content">{page.content}</RichText>

          <div className="flex items-center flex-wrap gap-x-4 gap-y-2">
            {page.category && <Category label={page.category} />}

            {page.labels
              .filter((_: any, index: any) => index > 0)
              .map((label) => (
                <Tag key={label.id} label={label} />
              ))}
          </div>

          <ShareButtons className="mt-5" page={page} />
        </div>

        <div className="hidden w1180:flex flex-col col-start-9 col-end-13 top-[106px] max-w-[284px] max-h-[calc(100vh-150px)]">
          <RecommendedArticles />
          <SideNavigationBanner page={page} />
        </div>
      </Container>

      <div className="hidden sm:block relative">
        <Kuro className="" />
      </div>

      <Container className="grid grid-cols-12 grid-gap-x md:max-w-[780px] w1180:max-w-full mx-auto">
        <Section
          title="最新のニュース"
          label="Latest News"
          className="col-start-1 col-end-13 w1180:col-start-2 w1180:col-end-9 mt-[160px]">
          <ul className="grid grid-gap-y">
            {latestNews.map((news) => (
              <li key={news.id}>
                <NewsItem news={news} />
              </li>
            ))}
          </ul>
        </Section>
      </Container>

      <NewsletterSubscription className="mt-[160px]" type="dark" />
    </Frame>
  );
};

export default NewsPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query NewsPage($id: String) {
    page: contentfulPage(id: { eq: $id }) {
      ...PageFields
    }
    news: allContentfulPage(
      filter: { type: { eq: "News" } }
      sort: { releasedAt: DESC }
      limit: 4
    ) {
      nodes {
        ...PageThumbnailFields
      }
    }
  }
`;
